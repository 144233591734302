.home-slider {

    min-height: 100px;
}

.home-slider img {
    width: 100%;
    max-height: 100%;
    height: 100%;
}

.home-slider .overlay-wrapper {
    height: 470px;
}


.slider-section .home-single-slide {
    min-height: 500px;
}

.home-slide-content {
    left: 50% !important;
    top: 50% !important;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 50% !important;
    margin: auto;
    transform: translateX(-50%) translateY(-50%);
}

.home-slide-title {
    font-family: LucidaFaxBoldItalic;
    color: var(--main-color);
    margin-bottom: 20px;
}

@media (max-width:992px) {

    .home-slide-content {
        width: 90% !important;
    }
}