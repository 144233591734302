/*******************************
********************************
********* PUBLIC STYLES ********
********************************
*******************************/

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-size: cover;
    background-repeat: no-repeat;
}

@font-face {
    font-family: LucidaFaxReg;
    src: url(./fonts/Sansita-Regular.ttf);
}

@font-face {
    font-family: LucidaFaxBoldItalic;
    src: url(./fonts/Sansita-Italic.ttf);
}

@font-face {
    font-family: LucidaFaxBold;
    src: url(./fonts/Sansita-Regular.ttf);
}

@font-face {
    font-family: LucidaFaxItalic;
    src: url(./fonts/Sansita-Italic.ttf);
}

:root {
    --bg-color: #000000;
    --bg-opacity-color: #000000b0;
    --main-color: #D0B661;
    --main-light-color: #EDE1B6;
    --main-dark-color: #B29D57;
    --main-opacity-color: #d0b661be;
    --light-color: #f9f9f9;
    --dark-color: #707070;
    --grey-color: #f9f9f9;
    --section-pd: 3.7rem 0;
    --toastify-toast-width: auto;
}

body {
    font-family: LucidaFaxReg;
    direction: ltr;
    text-align: left;
    overflow-x: hidden;
    color: var(--bg-color);
    font-size: 13.5px;
}

button,
button:focus {
    cursor: pointer;
    outline: none;
    box-shadow: none !important;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
    border-color: var(--bg-color);
    outline: 0;
    box-shadow: 0 0 0 0.2rem var(--bg-opacity-color);
}

.form-check-input:checked {
    background-color: var(--bg-color);
    border-color: var(--bg-color);
}

.dir-ltr {
    direction: ltr;
}

.dir-rtl {
    direction: rtl;
}

img {
    object-fit: cover;
    max-width: 100%;
}

a,
a:hover {
    text-decoration: none;
    transition: .3s;
    color: inherit;
}

input,
input:hover {
    outline: 0;
    outline: none;
}

.form-control:focus{
    border-color: var(--main-color);
    box-shadow: 0 0 0 .25rem var(--main-light-color);
}
p,
ul {
    margin: 0;
}

/* icons social */
.facebook {
    background-color: #3a5897;
}

.facebook-h:hover,
.facebook-c {
    color: #3a5897;
    border: 1px solid #3a5897;
}

.twitter {
    background-color: #0cf;
}

.twitter-h:hover,
.twitter-c {
    color: #0cf;
    border: 1px solid #0cf;
}

.telegram {
    background-color: #0094d6;
}

.telegram-h:hover,
.telegram-c {
    color: #0094d6;
    border: 1px solid #0094d6;
}

.youtube {
    background-color: #f00;
}

.youtube-h:hover,
.youtube-c {
    color: #f00;
    border: 1px solid #f00;
}

.skype {
    background-color: #00a9f0;
}

.skype-h:hover,
.skype-c {
    color: #00a9f0;
    border: 1px solid #00a9f0;
}

.google-plus {
    background-color: #dd4d3f;
}

.google-plus-h:hover,
.google-plus-c {
    color: #dd4d3f;
    border: 1px solid #dd4d3f;
}

.instagram {
    background-color: #fb3958;
}

.instagram-h:hover,
.instagram-c {
    color: #fb3958;
    border: 1px solid #fb3958;
}

/*******************************
********* CUSTOM STYLES ********
*******************************/
.img-thumbnail {
    padding: 0 1rem;
    border: none;
}

@media (min-width:993px) {
    [class*="mobile-"] {
        display: none;
    }
}

.section {
    padding: var(--section-pd);
}

.section-wrapper {
    position: relative;
    z-index: 20;
}

.section-heading {
    padding: 0 0 30px;
    color: var(--dark-color);
    width: max-content;
}

.section-title {
    color: var(--bg-color);
    font-size: 23px;
    font-weight: bold;
}

.section-line {
    position: relative;
    height: 2px;
    width: 100px;
    background-color: var(--main-color);
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
}

.section-line span {
    width: 10px;
    height: 10px;
    background-color: var(--main-light-color);
    border-radius: 1px;
    border: 1px solid var(--main-color);
    display: block;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translateY(-50%) translateX(50%) rotateZ(45deg)
}

.section-desc {
    margin-top: 30px;
    color: var(--bg-color);
    font-size: 15px;
}

.section-content {
    position: relative;
    z-index: 20;
}

.bg-grey {
    background-color: var(--grey-color);
}

.bg-main {
    background-color: var(--bg-color);
}

ul.list {
    list-style: none;
    margin: 0;
    padding: 0;
}


/*socialmedia list */
.socialmedia-list {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0 2px;
}

.socialmedia-list li {
    display: inline-block;
    padding: 0 2px;
}

.socialmedia-list li i {
    width: 30px;
    color: #fff;
    text-align: center;
    height: 30px;
    line-height: 30px;
    transition: 0.3s;
}

/*langs list */
.langs-list {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -45px;
    height: 30px;
    align-items: center;
}

.langs-list li {
    display: inline-block;
    color: var(--dark-color);
}

.langs-list li span {
    color: var(--main-color);
    padding-inline-start: 5px;
}

.overlay-wrapper {
    position: relative;
}

.overlay-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.read-more {
    text-decoration: none;
    padding: 7px 20px;
    color: #fff;
    background-color: var(--main-color);
    transition: all ease-in-out 0.3s;
    margin-top: 20px;
    display: block;
    width: max-content;
    border-radius: 2px;
}

.read-more:hover {
    color: var(--bg-color);
}

.read-more span {
    margin-inline-start: 10px;
    transition: .3s;
}

.read-more:hover span {
    margin-inline-end: 10px;
}

.short-desc {
    color: #868686;
}

.page {
    min-height: calc(100vh - 200px);
    padding: 20px 0;
}

.auth-page {
    background: #f9f9f9;
    background-size: cover;
}


.rtl input::placeholder {
    text-align: right;
}

/*is-loading*/
.is-loading .loading-text {
    background-color: var(--loading-color);
    margin-bottom: 5px;
    position: relative;
    min-height: 20px;
    min-width: 200px;
    max-width: max-content;
    color: var(--loading-color);
}

.is-loading .loading-text * {
    color: var(--loading-color) !important;
}

.is-loading .loading-text::after {
    position: absolute;
    content: '';
    bottom: 40%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 2px;
    background-color: #fff5;
}

.is-loading .loading-box {
    background-color: var(--loading-color) !important;
}

.is-loading .loading-img {
    width: 300px;
    height: 300px;
    background-color: var(--loading-color);
    border: none;
}


/******************************
*******************************
****** Product Details ********
*******************************
******************************/
.product-details {
    padding-top: 30px;
    padding-bottom: 30px;
}

.product-details .product-title {
    color: var(--bg-color);
    font-size: 26px;
    margin-bottom: 10px;
    font-family: LucidaFaxBold;
}

.product-details .product-rating {
    padding: 10px 0;
    display: flex;
    gap: 15px;
    align-items: center;
}

.product-details .product-desc {
    color: #505050;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    margin: 0;
}

.product-details .product-price {
    font-size: 20px;
    color: var(--main-color);
    font-family: LucidaFaxBold;
}

.product-details .actions-wrapper {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    align-items: center;
}

.actions-wrapper .quality-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
}

.product-details .action-btns {
    display: flex;
    gap: 5px;
}

.product-details .action-btn {
    background-color: var(--bg-color);
    outline: none;
    border: none;
    font-size: 15px;
    color: #fff;
    padding: 10px 20px;
}

.product-details .add-to-card {
    padding: 10px 40px;
}

.actions-wrapper .count-btns-wrapper {
    display: flex;
    gap: 15px;
    align-items: center;
}

.actions-wrapper .count-btns-wrapper .btn {
    border: none;
    border: 2px solid var(--bg-color);
    background: none;
    color: var(--bg-color);
    width: 30px;
    height: 30px;
    line-height: 100%;
    padding: 0;
    font-size: 22px;
    transform: rotateZ(45deg);
}

.actions-wrapper .count-btns-wrapper .btn span {
    transform: rotateZ(-45deg);
    display: block;
}

.images-other {
    margin-top: 15px;
    display: flex;
    gap: 5px;
}

.other-image-wrapper {
    width: 70px;
}

.other-image-wrapper:hover {
    cursor: pointer;
}

.other-image-wrapper.active {
    border: 1px solid var(--main-color);
}

.options-wrapper {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
}

.option-item-list {
    display: flex;
    gap: 5px;
}

.option-item--title {
    padding-bottom: 10px;
    font-weight: bold;
}

.option-item-list li {
    padding: 5px;
    border: 1px solid #e9e9e9;
}

.option-item-list li.active {
    border-color: var(--main-color);
    box-shadow: 0px 1px 1px var(--main-color);
}

@media (max-width:992px) {
    .product-details .actions-wrapper {
        display: block;
    }

    .actions-wrapper .quality-wrapper {
        margin-bottom: 20px;
    }
}

.products-section .react-reveal {
    height: 100%;
}

#map {
    width: 100% !important;
    height: 350px !important;
}

#map iframe {
    height: 100% !important;
    width: 100% !important;
}

.text-error {
    color: red !important;
}

.input-error {
    padding-inline-start: 60px;
    display: block;
    min-height: 10px;
}

.category-page .page-title {
    display: flex;
}

.cart-page .btn-primary {
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    color: var(--bg-color);
    font-size: 16px;
    padding-right: 30px;
    padding-left: 30px;
}

.cart-page .btn-primary:hover {
    background: none;
    border-color: var(--main-color);
    color: var(--bg-color)
}

.whatsapp-btn {
    width: 50px;
    height: 50px;
    line-height: 28px;
    text-align: center;
    font-size: 30px;
    background: #2bb641;
    color: #fff;
    border-radius: 50%;
    position: fixed;
    z-index: 1000;
    bottom: 20px;
    right: 20px;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 1030 !important;
}

.product-page .img-wrapper img {
    cursor: pointer;
}

.list .swiper-slide:last-child {
    text-align: right;
}

.list .swiper-slide:nth-child(2) {
    text-align: center;
}

@media (max-width:992px) {
    .list .swiper-slide {
        text-align: left !important;
    }

    .contact-item span,
    .contact-item a {
        font-size: 12px !important;
    }
}

.reviews {
    border: 1px solid #f8f8f8;
    padding: 15px 20px;
    background-color: #fefefe;
    font-size: 15px;
}

.reviews .reviews-title {
    font-size: 17px;
    margin-bottom: 25px;
    position: relative;
}

.reviews .reviews-title::after {
    position: absolute;
    bottom: -7px;
    left: 0;
    content: '';
    display: block;
    background-color: var(--main-color);
    height: 3px;
    width: 25px;
}

.reviews .form-control {
    font-size: inherit;
    margin-bottom: 10px;
}

.review-item .review-item-top {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
}

.review-item {
    margin-bottom: 10px;
    border: 1px solid #f2f2f2;
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
}

.review-item .review-item-top img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.review-item-text {
    margin-top: 20px;
    color: #525252;
}

@media (max-width: 992px) {
    .reviews .form-control {
        max-width: 100%;
    }
}
.btn-main{
    background-color: var(--bg-color);
    border: 1px solid var(--bg-color);
    color:#fff;
}
.btn-main:hover{
    background: none;
    border-color: var(--bg-color);
    color:var(--bg-color);
}